<template>
  <v-dialog v-model="dialog" persistent width="400">
    <v-card>
      <v-card-title class="text-h5" v-if="action === 'Activate'"
        >Activate Operator</v-card-title
      >
      <v-card-title class="text-h5" v-else>Deactivate Operator</v-card-title>
      <v-card-text
        ><p class="dialog-content" v-if="action === 'Activate'">
          Are you sure you want to activate operator?
        </p>
        <p class="dialog-content" v-else>
          Are you sure you want to deactivate operator?
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="var(--primary)"
          dark
          :loading="loading"
          @click="activateOperator"
          v-if="action === 'Activate'"
        >
          Activate</v-btn
        >
        <v-btn
          color="var(--primary)"
          dark
          :loading="loading"
          @click="deactivateOperator"
          v-else
        >
          Deactivate</v-btn
        >
        <v-btn color="grey darken-1" dark @click="close"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialog', 'operator', 'action'],
  data: () => ({
    loading: false,
  }),
  methods: {
    close() {
      this.$emit('refresh')
      this.$emit('update:dialog', false)
    },
    async activateOperator() {
      this.loading = true
      try {
        let payload = {}
        payload.email = this.operator.email
        await this.$store.dispatch('activate_admin', payload)
        this.$root.snackbar.show({
          message: 'Operator activated successfully',
          color: 'success',
        })
        this.close()
      } catch (error) {
        this.$root.snackbar.show({
          message: error.response.data.message,
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },
    async deactivateOperator() {
      this.loading = true
      try {
        let payload = {}
        payload.email = this.operator.email
        await this.$store.dispatch('deactivate_admin', payload)
        this.$root.snackbar.show({
          message: 'Operator deactivated successfully',
          color: 'success',
        })
        this.close()
      } catch (error) {
        this.$root.snackbar.show({
          message: error.response.data.message,
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-content {
  font-size: 16px;
}
</style>